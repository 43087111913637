<script setup lang="ts">
    import Drawer from 'primevue/drawer';
    import { isMobile } from '@/util/breakpoints';
    import { useI18n } from 'vue-i18n';
    import { useAdditionalProductAction, useAdditionalProductQuery } from '@/composables/additional-product';
    import AdditionalProductItem from '@/cart/components/AdditionalProductItem.vue';
    import { useAsyncTask } from 'vue-concurrency';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { useCartAction } from '@/composables/cart';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';

    const emits = defineEmits<{
        close: [];
    }>();

    const { t } = useI18n();
    const additionalProductAction = useAdditionalProductAction();
    const cartAction = useCartAction();
    const { addableAdditionalProducts } = useAdditionalProductQuery();
    const checkoutCartAction = useCheckoutCartAction();

    const fetchAdditionalProductsTask = useAsyncTask(async () => {
        await additionalProductAction.fetchAdditionalProductsIfMissing();
    }).perform();

    async function addAdditionalProductToCart(product: CtxProduct): Promise<void> {
        const variant = product.variants[0];

        if (variant?.id == null) {
            return;
        }

        const changeResponse = await cartAction.addLineItemByVariantId(product, variant.id, true);
        if (!('type' in changeResponse && 'message' in changeResponse)) {
            // no error, so update checkout cart
            checkoutCartAction.updateCheckoutCartLineItems(changeResponse.items);
        }

        emits('close');
    }
</script>

<template>
    <Drawer
        :visible="true"
        :position="isMobile ? 'full' : 'right'"
        :block-scroll="true"
        :style="{ width: '360px' }"
        @update:visible="emits('close')"
    >
        <template #header>
            <h3 class="text-xl-bold-line-height-auto">{{ t('CART.ADDITIONAL_PRODUCTS.TITLE') }}</h3>
        </template>
        <div v-if="fetchAdditionalProductsTask.isSuccessful" class="item-list">
            <AdditionalProductItem
                v-for="item in addableAdditionalProducts"
                :key="item.product.id"
                :product="item.product"
                :quantity-unit="item.quantityUnit"
                :price="item.price"
                :original-price="item.originalPrice"
                @add-to-cart="addAdditionalProductToCart(item.product)"
            ></AdditionalProductItem>
        </div>
        <div v-else-if="fetchAdditionalProductsTask.isRunning" class="loading-spinner">
            <LoadingSpinner></LoadingSpinner>
        </div>
    </Drawer>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .loading-spinner {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
    }
</style>
