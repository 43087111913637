<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import Card from 'primevue/card';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import ProductImage from '@/components/ProductImage.vue';
    import { computed } from 'vue';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { QuantityUnit } from '@containex/portal-backend-dto';
    import { calculateRentalPrice } from '@containex/portal-business-logic';
    import { useCartQuery } from '@/composables/cart.ts';

    const props = defineProps<{
        product: CtxProduct;
        quantityUnit: QuantityUnit;
        price: number;
        originalPrice: number;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [];
    }>();

    const { t } = useI18n();
    const { rentalStart, rentalEnd } = useCartQuery();
    const { formatPrice } = usePriceFormatter();

    const priceDisplay = computed(() => priceDisplayForCalculatedPrice());
    const priceDisplayOriginalPrice = computed(() => priceDisplayForOriginalPrice());

    const calculatedPrice = computed(() => {
        if (props.quantityUnit === QuantityUnit.DAYS) {
            return calculateRentalPrice(props.price, true, rentalStart.value, rentalEnd.value);
        }

        return props.price;
    });

    const hasDiscount = computed(() => props.originalPrice !== calculatedPrice.value);

    function priceDisplayForCalculatedPrice(): string {
        const formattedPrice = formatPrice(calculatedPrice.value);
        if (props.quantityUnit === QuantityUnit.DAYS) {
            return t('PRODUCT.PRICE_DAILY', { price: formattedPrice });
        }

        return formattedPrice;
    }

    function priceDisplayForOriginalPrice(): string {
        const formattedPrice = formatPrice(props.originalPrice);
        if (props.quantityUnit === QuantityUnit.DAYS) {
            return t('PRODUCT.PRICE_DAILY', { price: formattedPrice });
        }

        return formattedPrice;
    }
</script>

<template>
    <Card>
        <template #content>
            <div class="content">
                <ProductImage
                    :url="product.thumbnail ?? undefined"
                    :alt="product.title ?? ''"
                    class="product-image"
                ></ProductImage>
                <div class="info text-base-semibold-line-height-auto">
                    {{ product.title }}<br />
                    <template v-if="hasDiscount">
                        <span class="product-price-original">{{ priceDisplayOriginalPrice }}</span
                        ><br />
                        <span class="color-discount">{{ priceDisplay }}</span>
                    </template>
                    <template v-else>
                        {{ priceDisplay }}
                    </template>
                </div>
                <Button icon="pi pi-cart-plus" severity="primary" @click="emits('add-to-cart')" />
            </div>
        </template>
    </Card>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .content {
        display: flex;
        gap: 1ch;
        align-items: center;
    }

    .product-image {
        width: 50px;
    }

    .info {
        flex: 1;
    }

    .product-price-original {
        font-size: main.$font-size-2;
        font-weight: 400;
        text-decoration: line-through;
        color: main.$color-text;
    }
</style>
